import { Fragment, useEffect, useState } from "react";
import { Dialog, Transition } from "@headlessui/react";
import {
  useDeleteVehicleMutation,
  Vehicle,
} from "../graphql/generated/graphql";
import Image from "next/image";
import { useToast } from "../context/toast";
import Spinner from "./Spinner";
import { useSession } from "next-auth/react";
import { useRouter } from "next/router";
import { DELETION_CAUSES } from "../utils/constants";

export default function DeleteVehicleModal({
  modalState,
  redirectTo,
  setModalState,
}: {
  modalState: { open: boolean; data?: Vehicle };
  redirectTo?: string;
  setModalState: () => void;
}) {
  const router = useRouter();
  const session = useSession();
  const sessionData = session?.data;
  const user = sessionData?.user;

  const { toggleToast } = useToast();

  const { data, open } = modalState;
  const { _id, brand, model, year, thumbs } = data || {};

  const [deletionCause, setDeletionCause] = useState(null);

  const [deleteVehicle, { loading: deletingVehicle }] =
    useDeleteVehicleMutation({
      update(cache) {
        const normalizedId = cache.identify({ id: _id, __typename: "Vehicle" });
        cache.evict({ id: normalizedId });
        cache.gc();
      },
      onCompleted() {
        onClose();
        router.replace(redirectTo || `/${user?.username || ""}`);
      },
      onError(e) {
        console.log(e);
        toggleToast({
          open: true,
          title: "Error",
          description: "Hubo un error al eliminar la publicación",
          type: "error",
        });
      },
    });

  useEffect(() => {
    if (!open) {
      setDeletionCause(null);
    }
  }, [open]);

  function onClose() {
    setModalState();
  }

  function onDelete() {
    deleteVehicle({
      variables: {
        _id,
        deletionCause,
      },
    });
  }

  return (
    <Transition.Root show={modalState.open} as={Fragment}>
      <Dialog as="div" className="relative z-50" onClose={onClose}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-4 pt-5 pb-4 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-sm sm:p-6">
                <div>
                  <div className="flex justify-center">
                    <div className="relative w-full aspect-w-4 aspect-h-3">
                      {data ? (
                        <Image
                          className="rounded-md"
                          src={thumbs[0]}
                          fill
                          style={{
                            objectFit: "cover",
                          }}
                          alt="Imagen del vehículo"
                        />
                      ) : null}
                    </div>
                  </div>
                  <div className="mt-3 text-center sm:mt-5">
                    <Dialog.Title
                      as="h3"
                      className="text-lg font-medium leading-6 text-gray-900"
                    >
                      Eliminar publicación
                    </Dialog.Title>
                    <div className="mt-2">
                      <p className="text-sm text-gray-500">
                        Vas a eliminar la publicación:{" "}
                        <span className="capitalize">{brand}</span>{" "}
                        <span className="capitalize">{model}</span>{" "}
                        <span className="capitalize">{year}</span>
                      </p>
                    </div>

                    <div>
                      <p className="text-sm text-gray-500">
                        Nos encantaría conocer la razón
                      </p>
                      <fieldset className="mt-4">
                        <legend className="sr-only">Notification method</legend>
                        <div className="space-y-2">
                          {Object.keys(DELETION_CAUSES).map((key) => {
                            const { title } = DELETION_CAUSES[key];

                            return (
                              <div key={key} className="flex items-center">
                                <input
                                  id={key}
                                  name="notification-method"
                                  type="radio"
                                  defaultChecked={key === deletionCause}
                                  className="h-4 w-4 border-gray-300 text-orange-500 focus:ring-orange-500 text-input"
                                  onClick={() => setDeletionCause(key)}
                                />
                                <label
                                  htmlFor={key}
                                  className="ml-3 block text-sm leading-6 text-gray-900"
                                >
                                  {title}
                                </label>
                              </div>
                            );
                          })}
                        </div>
                      </fieldset>
                    </div>
                  </div>
                </div>
                <div className="mt-5 sm:mt-6 flex flex-col sm:flex-row gap-x-1">
                  <button
                    type="button"
                    className="inline-flex w-full justify-center rounded-md border border-gray-300 hover:border-gray-400 bg-white px-4 py-2 text-base font-medium text-gray-500 hover:text-gray-600 shadow-sm focus:outline-none focus:ring-2  focus:ring-offset-2 sm:text-sm"
                    onClick={onClose}
                  >
                    Cancelar
                  </button>
                  <button
                    type="button"
                    className="inline-flex gap-x-1 w-full justify-center rounded-md border border-transparent bg-red-500 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-red-600 disabled:hover:bg-red-500 focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-offset-2 sm:text-sm mt-1 sm:mt-0 disabled:opacity-70"
                    disabled={deletingVehicle || !deletionCause}
                    onClick={onDelete}
                  >
                    {deletingVehicle ? <Spinner className="h-5 w-5" /> : null}
                    Eliminar
                  </button>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
