import { useToast } from "../context/toast";
import {
  useAddItemToWatchlistMutation,
  useRemoveItemFromWatchlistMutation,
} from "../graphql/generated/graphql";

export default function useWatchlistMutations(vehicleData) {
  const _id = vehicleData._id;
  const { toggleToast } = useToast();

  const [addToWatchlist, { loading: addingToWatchlist }] =
    useAddItemToWatchlistMutation({
      update(cache) {
        cache.modify({
          fields: {
            getUserWatchlist(existingWatchlistItems = []) {
              return [vehicleData, ...existingWatchlistItems];
            },
          },
        });
      },
      onError() {
        toggleToast({
          open: true,
          title: "Error",
          description: "Hubo un error al agregar el vehículo a tus favoritos",
          type: "error",
        });
      },
    });

  const [removeFromWatchlist, { loading: removingFromWatchlist }] =
    useRemoveItemFromWatchlistMutation({
      update(cache) {
        cache.modify({
          fields: {
            getUserWatchlist(existingWatchlistItems = [], { readField }) {
              return existingWatchlistItems.filter(
                (vehicleRef) => _id !== readField("_id", vehicleRef)
              );
            },
          },
        });
      },
      onError() {
        toggleToast({
          open: true,
          title: "Error",
          description: "Hubo un error al remover el vehículo de tus favoritos",
          type: "error",
        });
      },
    });

  function addVehicleToWatchlist(e?: Event) {
    e?.stopPropagation();
    const variables = {
      itemId: _id,
    };
    addToWatchlist({
      variables,
    });
  }

  function removeVehicleFromWatchlist(e?: Event) {
    e?.stopPropagation();
    const variables = {
      itemId: _id,
    };
    removeFromWatchlist({
      variables,
    });
  }

  return {
    addVehicleToWatchlist,
    removeVehicleFromWatchlist,
    addingToWatchlist,
    removingFromWatchlist,
  };
}
