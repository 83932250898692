import { GetUserWatchlistQueryResult } from "../graphql/generated/graphql";

export const isInWatchlist = (
  getUserWatchlistResponse: GetUserWatchlistQueryResult,
  id: string
) => {
  const { data, loading, error } = getUserWatchlistResponse;

  if (loading || !data || error) {
    return false;
  }

  return data?.getUserWatchlist?.some((v) => v._id === id) || false;
};
